import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PaginatedList } from '@app/models/common/paginated-list.class';
import { WashSale, WashSaleVerification } from '@app/models/washsettlement/wash-sale.model';
import { WashDeviations } from '@app/models/washsettlement/wash-deviation.model';
import { Wash } from '@app/models/washsettlement/wash.model';
import { WashProgramWrapper } from '@app/models/washsettlement/wash-program.model';

@Injectable()
export class WashSettlementService {

  private uri: string = `${environment.routes.apiEndpoint}/wash-settlement`;

  constructor(private http: HttpClient) { }

  getSales(
    searchText: string | null = null,
    completedAtDateFrom: Date | null = null,
    completedAtDateTo: Date | null = null,
    isSubscription: boolean | null = null,
    hasWashes: boolean | null = null,
    washProgramIds: string[] = [],
    paymentTypes: string[] = [],
    sortByField: string = 'SourceDate',
    sortDecending: boolean = true,
    pageIndex: number = 1,
    pageSize: number = 100) {
    let filters = `pageSize=${pageSize}&pageIndex=${pageIndex}&sortByField=${sortByField}&sortDecending=${sortDecending}`;
    if (searchText) filters += `&searchText=${searchText}`;
    if (completedAtDateFrom) filters += `&completedAtDateFrom=${completedAtDateFrom.toISOString()}`;
    if (completedAtDateTo != null) {
      // Avoid modifying the passed in Date object
      completedAtDateTo = new Date(completedAtDateTo);
      completedAtDateTo.setDate(completedAtDateTo.getDate() + 1);
      filters += `&completedAtDateTo=${completedAtDateTo.toISOString()}`;
    }

    if (isSubscription !== null) filters += `&isSubscription=${isSubscription}`;
    if (hasWashes !== null) filters += `&hasWashes=${hasWashes}`;
    if (washProgramIds.length) filters += `&washProgramIds=${washProgramIds.join('&washProgramIds=')}`;
    if (paymentTypes.length) filters += `&paymentTypes=${paymentTypes.join('&paymentTypes=')}`;
    return this.http.get<PaginatedList<WashSale>>(`${this.uri}/sales?${filters}`);
  }

  getDeviations(dateFrom: Date | null = null, dateTo: Date | null = null) {
    let filters = '';
    if (dateFrom) filters += `dateFrom=${dateFrom.toISOString()}`;
    if (dateTo != null) {
      // Avoid modifying the passed in Date object
      dateTo = new Date(dateTo);
      dateTo.setDate(dateTo.getDate() + 1);
      filters += `&dateTo=${dateTo.toISOString()}`;
    }
    return this.http.get<WashDeviations>(`${this.uri}/sales/deviations?${filters}`);
  }

  getWashes(
    searchText: string | null = null,
    saleIds: string[] = [],
    stationIds: string[] = [],
    washProgramIds: string[] = [],
    isSubscription: boolean | null = null,
    startedDateFrom: Date | null = null,
    startedDateTo: Date | null = null,
    sortByField: string = 'SourceDate',
    sortDecending: boolean = false,
    pageIndex: number = 1,
    pageSize: number = 10) {
    let filters = `pageSize=${pageSize}&pageIndex=${pageIndex}&sortByField=${sortByField}&sortDecending=${sortDecending}`;
    if (searchText) filters += `&searchText=${searchText}`;
    if (saleIds.length) filters += `&saleIds=${saleIds.join('&saleIds=')}`;
    if (stationIds.length) filters += `&stationIds=${stationIds.join('&stationIds=')}`;
    if (washProgramIds.length) filters += `&washProgramIds=${washProgramIds.join('&washProgramIds=')}`;
    if (isSubscription !== null) filters += `&isSubscription=${isSubscription}`;
    if (startedDateFrom) filters += `&startedDateFrom=${startedDateFrom.toISOString()}`;
    if (startedDateTo != null) {
      // Avoid modifying the passed in Date object
      startedDateTo = new Date(startedDateTo);
      startedDateTo.setDate(startedDateTo.getDate() + 1);
      filters += `&startedDateTo=${startedDateTo.toISOString()}`;
    }
    return this.http.get<PaginatedList<Wash>>(`${this.uri}/washes?${filters}`);
  }

  // If verifications endpoint is needed for non-ignored verifications, update to include a parameter for ignored status
  getIgnoredVerifications(
    saleDateFrom: Date | null = null,
    saleDateTo: Date | null = null,
    sortByField: string = 'ignoreInfo.ignoredAt',
    sortDecending: boolean = false,
    pageIndex: number = 1,
    pageSize: number = 10) {
    let filters = `pageSize=${pageSize}&pageIndex=${pageIndex}&sortByField=${sortByField}&sortDecending=${sortDecending}&hasBeenIgnored=true`;
    if (saleDateFrom) filters += `&saleDateFrom=${saleDateFrom.toISOString()}`;
    if (saleDateTo) {
      // Avoid modifying the passed in Date object
      saleDateTo = new Date(saleDateTo);
      saleDateTo.setDate(saleDateTo.getDate() + 1);
      filters += `&saleDateTo=${saleDateTo.toISOString()}`;
    }
    return this.http.get<PaginatedList<WashSaleVerification>>(`${this.uri}/sales/verifications?${filters}`);
  }

  getWashPrograms() {
    return this.http.get<WashProgramWrapper>(`${this.uri}/washes/programs`);
  }

  patchIgnoreVerification(verificationId: string, hasBeenIgnored: boolean, ignoreReason: string = '') {
    const body = {
      'hasBeenIgnored': hasBeenIgnored,
      'ignoreReason': ignoreReason
    };
    return this.http.patch(`${this.uri}/sales/verifications/${verificationId}/ignore`, body);
  }

  postRunExport() {
    return this.http.post(`${this.uri}/export/run`, null);
  }
}
